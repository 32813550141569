import React from "react";
import ImageHolder from "../../components/ImageHolder";
import MainLayout from "../../layouts/Main";

import "./styles.scss";

const HoodiesPage = () => {
  return (
    <MainLayout>
      <main>
        <h2>Hoodies</h2>
        <ImageHolder
          src={require("./../../assets/images/hoodies/hoodies.webp")}
        />
      </main>
    </MainLayout>
  );
};

export default HoodiesPage;
