import React from "react";

import "./styles.scss";

const Card = ({ title, imgSrc, onClick }) => {
  return (
    <div className="card" onClick={onClick}>
      <section className="section1">
        <img src={imgSrc} />
      </section>
      <section className="section2">{title}</section>
    </div>
  );
};

export default Card;
