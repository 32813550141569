import { Route, Routes } from "react-router";
import "./App.css";
import DecalsPage from "./pages/DecalsPage";
import HomePage from "./pages/HomePage";
import HoodiesPage from "./pages/HoodiesPage";
import SweatshirtsPage from "./pages/SweatshirtsPage";
import TshirtsPage from "./pages/TshirtsPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/tshirts" element={<TshirtsPage />} />
        <Route path="/hoodies" element={<HoodiesPage />} />
        <Route path="/sweatshirts" element={<SweatshirtsPage />} />
        <Route path="/decals" element={<DecalsPage />} />
      </Routes>
    </div>
  );
}

export default App;
