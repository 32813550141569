import React from "react";
import Card from "../../components/Card";
import MainLayout from "../../layouts/Main";

import ImageHolder from "./../../components/ImageHolder";

import "./styles.scss";

const HomePage = () => {
  return (
    <MainLayout>
      <main>
        <section>
          <h2>Designs</h2>
          <div className="cards">
            <Card
              title={"Decals"}
              onClick={() => (window.location.href = "/decals")}
              imgSrc={require("./../../assets/images/decal.webp")}
            ></Card>
          </div>
        </section>
        <section>
          <h2>What we have</h2>

          <div className="cards">
            <Card
              title={"TShirts"}
              onClick={() => (window.location.href = "/tshirts")}
              imgSrc={require("./../../assets/images/tshirt.webp")}
            />
            <Card
              title={"Hoodies"}
              onClick={() => (window.location.href = "/hoodies")}
              imgSrc={require("./../../assets/images/hoodie.webp")}
            />
            <Card
              title={"Sweat Shirts"}
              onClick={() => (window.location.href = "/sweatshirts")}
              imgSrc={require("./../../assets/images/sweatshirt.webp")}
            />
          </div>
        </section>

        <section className="about">
          <h2>About Us</h2>
          <h3 style={{ color: "black" }}>Custom T-shirt store</h3>
          <p>Location: Jersey Gardens Mall</p>
          <ImageHolder
            src={require("./../../../src/assets/images/tees.webp")}
          />
        </section>
      </main>
    </MainLayout>
  );
};

export default HomePage;
