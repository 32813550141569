import React from "react";
import "./styles.scss";

const ImageHolder = ({ src }, props) => {
  return (
    <div className="holder">
      <img src={src} {...props} />
    </div>
  );
};

export default ImageHolder;
