import React from "react";
import ImageHolder from "../../components/ImageHolder";
import MainLayout from "../../layouts/Main";
import "./styles.scss";

const DecalsPage = () => {
  return (
    <MainLayout>
      <main>
        <h2>Decals</h2>
        <ImageHolder src={require("./../../assets/images/decals/1.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/2.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/3.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/4.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/5.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/6.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/7.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/8.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/9.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/10.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/11.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/12.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/14.webp")} />
        <ImageHolder src={require("./../../assets/images/decals/15.webp")} />
      </main>
    </MainLayout>
  );
};

export default DecalsPage;
