import React from "react";

import "./styles.scss";

const Header = () => {
  return (
    <header className="header">
      <div className="logo" onClick={() => (window.location.href = "/")}>
        <h1>Custom Tees</h1>
      </div>
    </header>
  );
};

export default Header;
