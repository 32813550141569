import React from "react";
import ImageHolder from "../../components/ImageHolder";
import MainLayout from "../../layouts/Main";
import "./styles.scss";

const SweatshirtsPage = () => {
  return (
    <MainLayout>
      <main>
        <h2>Sweat Shirts</h2>
        <ImageHolder
          src={require("./../../assets/images/sweatshirts/sweatshirts.webp")}
        />
      </main>
    </MainLayout>
  );
};

export default SweatshirtsPage;
