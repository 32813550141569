import React from "react";
import ImageHolder from "../../components/ImageHolder";
import MainLayout from "../../layouts/Main";

import "./styles.scss";

const TshirtsPage = () => {
  return (
    <MainLayout>
      <main>
        <h2>TShirts</h2>
        <ImageHolder
          src={require("./../../assets/images/tshirts/adulttshirts.webp")}
        />
      </main>
    </MainLayout>
  );
};

export default TshirtsPage;
