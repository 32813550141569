import { Instagram, LocalPhone } from "@mui/icons-material";
import React from "react";
import Button from "../Button";

import "./styles.scss";

const Footer = () => {
  return (
    <footer>
      <Button
        onClick={() =>
          (window.location.href = "https://www.instagram.com/customizeurstuff/")
        }
      >
        <Instagram />
        Instagram
      </Button>
      <Button onClick={() => (window.location.href = "tel:9086270884")}>
        <LocalPhone /> (908)-627-0884
      </Button>
    </footer>
  );
};

export default Footer;
